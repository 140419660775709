<!-- 简历详情页面 -->
<template>
  <div class="box">
    <div class="wrap" v-loading="loading">
       

      <!-- 基本信息 -->
      <div style="overflow:hidden">
        <div class="img-wrap">
          <img
            src="./../../assets/images/test/boy.png"
            alt
            v-if="data.basic_gender === '男'"
          />
          <img src="./../../assets/images/test/girl.png" alt v-else />
        </div>

        <div class="all-base">
          <div class="name">{{ data.basic_name }}</div>
          <div class="base">
            <span>{{ data.basic_gender }}</span>
            <i></i>
            <span>{{ data.basic_age }}岁</span>
            <i></i>
            <span v-if="data.resume_education_experience_list">{{
              data.resume_education_experience_list[0].education_background
            }}</span>
            <i></i>
            <span>{{ data.basic_exp_year }}年</span>
          </div>
          <!-- <div class="tag">
            <span>沟通力强</span>
            <span>执行力强</span>

            <span>学习力强</span>

            <span>诚信正直</span>
          </div>-->
          <div class="expect">
            <!-- <p>
              求职状态：
              <span>求职中</span>
            </p>-->
            <p>
              期望职位：
               <span >{{data.expect_position_name_list.join(" , ")}}</span> 

              <!-- <span
                v-for="(item, index) in data.expect_position_name_list"
                :key="index"
                style="margin-right:7px"
                >{{ item }}</span
              > -->
            </p>
            <p>
              期望地点：
              <span
                v-for="(item, index) in data.expect_position_place_list"
                :key="index"
                style="margin-right:7px"
                >{{ item }}</span
              >
            </p>
            <p
              v-if="
                data.resume_expect_position_list &&
                  data.resume_expect_position_list.length > 0
              "
            >
              期望薪资：
              <span
                >{{ data.resume_expect_position_list[0].salary_min }}-{{
                  data.resume_expect_position_list[0].salary_max
                }}元</span
              >
            </p>

             <p>
              手机号：
              <span >{{ data.basic_phone }}</span
              >
            </p>

          </div>
        </div>
      </div>

      <!-- 工作经历 -->
      <div
        class="work"
        v-if="
          data.resume_work_experience_list &&
            data.resume_work_experience_list.length > 0
        "
      >
        <div v-if="data.resume_work_experience_list[0].position_name != ''">
          <div class="title">工作经历</div>
          <div
            v-for="(item, index) in data.resume_work_experience_list"
            :key="index"
          >
            <p class="company-name">{{ item.company_name }}</p>
            <p v-if="item.end_month != 0">
              工作时间：
              <span
                >{{ item.start_year }}年{{ item.start_month }}月-{{
                  item.end_year
                }}年{{ item.end_month }}月</span
              >
            </p>
            <p v-if="item.upper != 0">
              薪资水平：
              <span>{{ item.floor }}-{{ item.upper }}</span>
            </p>
            <p v-if="item.position_name != ''">
              在职职位：
              <span>{{ item.position_name }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 教育经历 -->
      <div
        class="edu"
        v-if="
          data.resume_education_experience_list &&
            data.resume_education_experience_list.length > 0
        "
      >
        <div v-if="data.resume_education_experience_list[0].school != ''">
          <div class="title">教育经历</div>
          <p
            v-for="(item, index) in data.resume_education_experience_list"
            :key="index"
          >
            <span v-if="item.school != ''">{{ item.school }}</span>
            <span v-if="item.end_month != 0">
              <i></i>
              {{ item.start_year }}年{{ item.start_month }}月-{{
                item.end_year
              }}年{{ item.end_month }}月
            </span>
            <span v-if="item.major != ''">
              <i></i>
              {{ item.major }}
            </span>
          </p>
        </div>
      </div>
      <!-- 自我评价 -->
      <div class="self" v-if="!loading">
        <div
          v-if="data.basic_self_evaluation && data.basic_self_evaluation != ''"
        >
          <div class="title">自我评价</div>
          <div class="self-word">{{ data.basic_self_evaluation }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState, mapActions } from 'vuex'
import { getResumeDetails, downResume } from '@/api/resume'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      id: '',
      data: {},

      loading: true,
      //获取简历
      resumeGet: false,
      //标记
      signShow: false,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    ...mapActions({
      getResumeIsDown: 'tool/getResumeIsDown',
    }),
    //下拉菜单
    commandHandle(command) {
      this.command = command
    },
    getData() {
      let obj = { resume_id: this.id }
      getResumeDetails(obj).then((res) => {
        console.log(res)
        if (res.code === 0) {
          this.data = res.data

          this.$nextTick(() => {
            this.loading = false
          })
        }
      })
    },
    //下载简历
    down() {
      this.$confirm('是否获取该简历?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let data = {
            resume_id: this.id,
          }
          downResume(data).then((res) => {
            if (res.code === 0) {
              this.getData()
              this.getResumeIsDown()
              this.$message({
                type: 'success',
                message: res.msg,
              })
            } else {
              this.$message({
                message: res.msg,
                type: 'warning',
              })
            }
          })
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        })
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.id = this.$route.query.id
    this.getData()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.box {
  @include padding;
  min-width: 1100px;
  min-height: 70vh;
  padding-bottom: 20px;
}
.wrap {
  padding: 20px 50px;
  min-height: 80vh;
  // overflow: auto;
  // height: calc(90vh - 100px);
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: $radius;
  box-shadow: $box-shadow;
}
.get-btn-wrap {
  width: 100%;
  height: 50px;
}
.get-btn {
  float: right;
}

.get-btn::after {
  content: '';
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}

/* 头像 */
.img-wrap {
  width: 110px;
  height: 110px;
  float: left;
}
.img-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.all-base {
  float: left;
  margin-left: 120px;
}
.name {
  font-size: 28px;
  color: $jd_fontColor;
  margin-bottom: 10px;
}
.base {
  font-size: $jd_fontSize;
  color: $selfColor;
}
/* 基本信息间隔 */
i {
  margin: 0 15px;
  border-right: 1px solid #ccc;
}
// 标签

.tag span {
  width: 80px;
  height: 30px;
  display: inline-block;
  margin-top: 20px;
  margin-left: 10px;
  text-align: center;
  line-height: 30px;
  background-color: $main_bc;
  font-size: $jd_fontSize;
  color: $main_color;
}
// 期望
.expect p {
  font-size: $jd_fontSize;
  color: $jd_fontColor;
}
.expect p span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin: 20px;
}
.work,
.edu,
.self {
  margin-top: 39px;
}
.work {
  clear: both;
}
.company-name {
  font-size: 18px;
  padding-left: 10px !important;
  color: $jd_fontColor;
}
.work p {
  font-size: $jd_fontSize;
  color: $jd_fontColor;
  padding: 0 30px;
  margin: 10px 0 0 0;
}
.work span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.edu p {
  padding: 0 30px;
}
.edu p span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.self-word {
  font-size: $jd_fontSize;
  color: $selfColor;
  padding: 0px 30px;
}
</style>
