var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"wrap"},[_c('div',{staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"img-wrap"},[(_vm.data.basic_gender === '男')?_c('img',{attrs:{"src":require("./../../assets/images/test/boy.png"),"alt":""}}):_c('img',{attrs:{"src":require("./../../assets/images/test/girl.png"),"alt":""}})]),_c('div',{staticClass:"all-base"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.data.basic_name))]),_c('div',{staticClass:"base"},[_c('span',[_vm._v(_vm._s(_vm.data.basic_gender))]),_c('i'),_c('span',[_vm._v(_vm._s(_vm.data.basic_age)+"岁")]),_c('i'),(_vm.data.resume_education_experience_list)?_c('span',[_vm._v(_vm._s(_vm.data.resume_education_experience_list[0].education_background))]):_vm._e(),_c('i'),_c('span',[_vm._v(_vm._s(_vm.data.basic_exp_year)+"年")])]),_c('div',{staticClass:"expect"},[_c('p',[_vm._v(" 期望职位： "),_c('span',[_vm._v(_vm._s(_vm.data.expect_position_name_list.join(" , ")))])]),_c('p',[_vm._v(" 期望地点： "),_vm._l((_vm.data.expect_position_place_list),function(item,index){return _c('span',{key:index,staticStyle:{"margin-right":"7px"}},[_vm._v(_vm._s(item))])})],2),(
              _vm.data.resume_expect_position_list &&
                _vm.data.resume_expect_position_list.length > 0
            )?_c('p',[_vm._v(" 期望薪资： "),_c('span',[_vm._v(_vm._s(_vm.data.resume_expect_position_list[0].salary_min)+"-"+_vm._s(_vm.data.resume_expect_position_list[0].salary_max)+"元")])]):_vm._e(),_c('p',[_vm._v(" 手机号： "),_c('span',[_vm._v(_vm._s(_vm.data.basic_phone))])])])])]),(
        _vm.data.resume_work_experience_list &&
          _vm.data.resume_work_experience_list.length > 0
      )?_c('div',{staticClass:"work"},[(_vm.data.resume_work_experience_list[0].position_name != '')?_c('div',[_c('div',{staticClass:"title"},[_vm._v("工作经历")]),_vm._l((_vm.data.resume_work_experience_list),function(item,index){return _c('div',{key:index},[_c('p',{staticClass:"company-name"},[_vm._v(_vm._s(item.company_name))]),(item.end_month != 0)?_c('p',[_vm._v(" 工作时间： "),_c('span',[_vm._v(_vm._s(item.start_year)+"年"+_vm._s(item.start_month)+"月-"+_vm._s(item.end_year)+"年"+_vm._s(item.end_month)+"月")])]):_vm._e(),(item.upper != 0)?_c('p',[_vm._v(" 薪资水平： "),_c('span',[_vm._v(_vm._s(item.floor)+"-"+_vm._s(item.upper))])]):_vm._e(),(item.position_name != '')?_c('p',[_vm._v(" 在职职位： "),_c('span',[_vm._v(_vm._s(item.position_name))])]):_vm._e()])})],2):_vm._e()]):_vm._e(),(
        _vm.data.resume_education_experience_list &&
          _vm.data.resume_education_experience_list.length > 0
      )?_c('div',{staticClass:"edu"},[(_vm.data.resume_education_experience_list[0].school != '')?_c('div',[_c('div',{staticClass:"title"},[_vm._v("教育经历")]),_vm._l((_vm.data.resume_education_experience_list),function(item,index){return _c('p',{key:index},[(item.school != '')?_c('span',[_vm._v(_vm._s(item.school))]):_vm._e(),(item.end_month != 0)?_c('span',[_c('i'),_vm._v(" "+_vm._s(item.start_year)+"年"+_vm._s(item.start_month)+"月-"+_vm._s(item.end_year)+"年"+_vm._s(item.end_month)+"月 ")]):_vm._e(),(item.major != '')?_c('span',[_c('i'),_vm._v(" "+_vm._s(item.major)+" ")]):_vm._e()])})],2):_vm._e()]):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"self"},[(_vm.data.basic_self_evaluation && _vm.data.basic_self_evaluation != '')?_c('div',[_c('div',{staticClass:"title"},[_vm._v("自我评价")]),_c('div',{staticClass:"self-word"},[_vm._v(_vm._s(_vm.data.basic_self_evaluation))])]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }